

@media screen and (min-width: 561px) {
    #searchBox.homepage {
        max-width: initial !important;
    }
}


#searchBox.homepage {
    @media screen and (min-width: 561px) {
        
        #frm-drugSearchForm {
            //max-width: 75% !important; 
            margin-left: 12.5% !important;
        }
        
        
        .searchBtn {
            max-width: 170px !important;
        }
        
        #contraindication-list {
            width: calc(100% - 170px) !important;
        }
    }
    
    
    @media screen and (max-width: 992px) {
        .searchBtn-for-small {
            width: initial !important;       
        }
        
        .button-contaner {
            max-width: 100% !important;
        }
        
        button {
            height: 45px !important;
        }
    }
}